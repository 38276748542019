.rating-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto;
    gap: 20px;
    width: 70%;
    margin: 200px auto 50px auto;
}

.rating-pic {
    filter: grayscale(30%);
}

.rating-cell {
    text-align: center;
}

.rating-cell img {
    width: 100px;
    height: auto;
    display: block;
    margin: 0 auto;
}

@media (max-width: 750px) {
    .rating-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}
