@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');

.wrapper {
    height: 100%;
}

.contact-form {
    width: 30%;
    max-width: 600px;
    margin: 0 auto;
    border-radius: 5px;
    background-color: #333;
    border: 1px solid #192135;
    padding: 20px;
    padding-bottom: 60px;
}

.contact-form-header {
    margin-top: 160px;
    margin-bottom: 20px;
    font-size: 35px;
    text-align: center;
}

.send-btn {
    float: right;
    margin-right: 20px;
}

.go-back-btn {
    float: left;
    margin-left: 20px;
}

.separator-block-2 {
    margin-top: 180px;
    margin-bottom: 180px;
}

.form-input {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    color: #000;
    background-color: #eee;
    width: 100%;
    padding: 12px;
    border: 1px solid #192135;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    transition: border-color 0.3s, box-shadow 0.3s;
    resize: vertical;
}

.form-input:focus {
    border-color: #565;
    outline: none;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
                0 0 8px rgba(86, 85, 86, 0.8);
}

@media (max-width: 1400px) {
    .contact-form {
        min-width: 400px;
    }

}

@media (max-width: 750px) {
    .contact-form-header {
        margin-top: 100px;
        font-size: 25px;
    }

    .contact-form {
        width: 90%;
        max-width: 400px;
        min-width: unset;
        padding: 10px;
        padding-bottom: 50px;
    }
}
