@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');

nav {
    position: fixed;
    width: 100%;
    /* max-width: 2048px; */
    margin: 0 auto;
    top: 0;
    left: 0;
    transform: none;
    height: 90px;
    display: grid;
    grid-template-areas: "logo nav";
    text-decoration: none;
    z-index: 1000;
    color: #eee;
    background: linear-gradient(to bottom, rgba(18, 18, 18, 0.7) 0%, rgba(18, 18, 18, 0) 90%);
}

.pab {
    height: 30px;
    width: 40px;
}

.title {
    grid-area: logo;
    font-size: 18px;
    float: left;
    padding-left: 10%;
    padding-top: 35px;
}

.hamburger-icon {
    grid-area: nav;
    display: block;
    cursor: pointer;
    justify-self: end;
    padding-right: 12%;
    padding-top: 30px;
}

.line {
    width: 30px;
    height: 4px;
    background-color: #ddd;
    margin: 6px 0;
    transition: 0.4s;
}

.line.open:nth-child(1) {
    transform: rotate(-45deg) translate(-8px, 6px);
}

.line.open:nth-child(2) {
    opacity: 0;
}

.line.open:nth-child(3) {
    transform: rotate(45deg) translate(-8px, -6px);
}

.pagelinks {
    font-family: 'Montserrat', sans-serif;
    display: none;
    grid-area: nav;
    grid-template-columns: repeat(4, auto);
    align-items: center;
    justify-items: center;
    font-size: 18px;
    text-decoration: none;
}

.pagelinks.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 80px;
    right: 10px;
    padding: 40px;
    background-color: #121212;
    border: 1px solid #222;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.pagelinks.open a {
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 20px;
    font-size: 28px;
    color: #ddd;
    background-color: #121212;
    text-decoration: none;
}

@media (min-width: 750px) {
    .pagelinks {
        display: grid;
    }

    .hamburger-icon {
        display: none;
    }
}
