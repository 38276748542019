
#contact {
    margin-top: 150px;
}

.contact-header {
    font-weight: bolder;
    font-size: 45px;
    margin-top: 50px;
    padding-bottom: 20px;
    text-align: center;
}

.contact-content {
    margin: 0 auto;
    text-align: center;
    padding-bottom: 100px;
}
