.fade-in-out-section {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease-out, visibility 0s linear 1s;
    will-change: opacity, visibility;
}

.fade-in-out-section.is-visible {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
}