@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
    min-height: 900px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;

    /* background-image: url('../resources/wallpaper.jpeg');
    filter: grayscale(0%) opacity(100%) saturate(30%); */

    background-image: url('../resources/wallpaper2.jpg');
    filter: grayscale(0%) opacity(60%) saturate(90%);
}

@media (max-width: 750px) {
    .background-image {
        max-height: 800px;
        min-height: 700px;
    }
}
