#experience {
    margin-top: 150px;
}

.experience-list {
    position: relative;
    margin: 20px 0;
    width: 100%;
}

/* Container for title, company, descr. */
.experience-container {
    display: flex;
    align-items: flex-start;
    position: relative;

    margin: 0 auto 30px auto;
    width: 40%;
    max-width: 650px;
}

.timeline-container {
    margin-top: 10px;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timeline-dot {
    width: 9px;
    height: 9px;
    background-color: rgb(252, 200, 108);
    border-radius: 50%;

    box-shadow: 0 0 10px rgba(252, 200, 108, 0.6),
                0 0 20px rgba(252, 200, 108, 0.4),
                0 0 30px rgba(252, 200, 108, 0.2);

    animation: glow 1.5s infinite alternate;
}

@keyframes glow {
    from {
        box-shadow: 0 0 10px rgba(252, 200, 108, 0.4),
                    0 0 20px rgba(252, 200, 108, 0.2),
                    0 0 30px rgba(252, 200, 108, 0.0);
    }
    to {
        box-shadow: 0 0 10px rgba(252, 200, 108, 0.8),
                    0 0 20px rgba(252, 200, 108, 0.6),
                    0 0 30px rgba(252, 200, 108, 0.4);
    }
  }

.timeline-vertical-line {
    margin-top: 25px;
    position: absolute;
    top: 12px;
    width: 2px;
    background-color: rgb(252, 200, 108);
    flex-grow: 1;
    height: 85%;
}

.experience-details {
    text-align: left;
    width: 80%;
}

.job-title {
    font-weight: bold;
    font-size: 24px;
}

.company-name {
    font-size: 19px;
    padding-top: 5px;
}

.description {
    margin-top: 8px;
    max-width: 600px;
}

@media (max-width: 750px) {
    .experience-item {
        flex-direction: column;
    }

    .experience-container {
        margin: 0 auto 30px auto;
        width: 90%;
    }

    .experience-details {

    }

    .job-title {
        font-size: 19px;
    }

    .company-name {
        font-size: 16px;
    }

    .description {
        font-size: 15px;
    }
}