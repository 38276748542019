
.introduction {
    text-align: left;
    padding: 2% 25% 0% 15%;
    margin-top: 300px;
    position: relative;
    max-width: 800px;
}

.name {
    padding-top: 15%;
    padding-bottom: 50px;
    font-size: 48px;
}

.arrow-down {
    animation: fadeInOut 3s ease-in-out infinite;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    cursor: pointer;
    margin: 100px 0px 350px 0px;
    filter: grayscale(0%) opacity(80%) saturate(50%);
}

@keyframes fadeInOut {
    0%, 40%, 60%, 100% { opacity: 1; }
    50% { opacity: 0; }
}

@media (max-width: 750px) {
    .introduction {
        margin-top: 100px;
    }

    .name {
        font-size: 30px;
    }
}