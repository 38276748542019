@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

html {
    height: 100%;
}

#root {
    width: 100%;
    overflow-x: hidden;
}

body {
    font-family: 'Montserrat', sans-serif;
    background-color: #121212;
    color:#eee;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}

h3 {
    margin-top: 80px;
}

::selection {
    background-color: rgba(119, 119, 119, 0.5);
}

.text-wrapper {
    padding: 0;
}

.chapter-wrapper {
    text-align: center;
}

.chapter-header {
    margin: 0 auto 50px auto;
    font-weight: 800;
    font-size: 35px;
    text-align: left;
    max-width: 600px;
}

.chapter-content {
    font-size: 15px;
    text-align: left;
    margin: 0 auto;
    max-width: 600px;
}

/* LINK STYLE */
a {
    text-decoration: none;
    transition: all .2s ease-in-out;
}

a:hover {
    cursor:pointer;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}

/* FONTS */
.theme-font {
    font-family: "Caveat", cursive;
}

.intro-font {
    font-family: "Playfair Display", serif;
    /* font-family: 'Montserrat', sans-serif; */
}

.header-font {
    font-family: "Playfair Display", serif;
}

.content-font {
    font-family: 'Montserrat', sans-serif;
}

/* COLORS */
.theme-color {
    color: rgba(0, 0, 0, 0.4);
}

.mellow-color {
    color: #aaa;
}

/* BUTTON */
button:hover {
    background-color: #333;
}

button {
    background-color: #222;
    color: #ddd;
    border: 1px solid #777;
    padding: 12px 20px;
    border-radius: 4px;
    cursor: pointer;
}

img {
    position: relative;
    border: 0px solid #293145;
}

@media (max-width: 750px) {
    .chapter-wrapper {
        margin-top: 200px;
        text-align: center;
    }

    .chapter-header {
        text-align: center;
    }

    .text-wrapper {
        padding: 0 30px;
    }
}