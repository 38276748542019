#skills {
    margin-top: 150px;
}

.skills {
    text-align: justify;
}

.skill-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 50px;
}

.skill-cell {
    background-color: rgb(45, 45, 45);
    border-radius: 15%;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.skill-cell:hover {
    transform: scale(0.95); /* Increase size by 20% */
}

.skill-name {
    width: 100%;
    padding-top: 20px;
    text-align: center;
}

.skill-grid img {
    height: 80px;
}

@media (max-width: 950px) {
    .skill-grid {
        grid-template-columns: repeat(2, 1fr);
        max-width: 410px;
    }
}

@media (max-width: 750px) {
    .skills {
        text-align: justify;
    }

    .skill-grid {
        max-width: 280px;
    }

    .skill-cell {
        width: 140px;
        height: 140px;
    }

    .skill-grid img {
        height: 50px;
    }
}