footer {
    text-align: center;
    width: 100%;
    height: 50px;
    margin: 0 auto;
    padding: 20px 0 20px 0;
}

.footer-text {

}

@media (max-width: 750px) {
    footer {
        font-size: 12px;
    }
}
