#about {
    margin-top: 500px;
}

.about {
    text-align: justify;
}

@media (max-width: 750px) {
    #about {
        margin-top: 450px;
    }
}